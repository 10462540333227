import { Routes } from '@angular/router';
import { userResolver } from './core/resolvers/user.resolver';

export const routes: Routes = [
    {
        path: 'pvp',
        resolve: {
            user: userResolver
        },
        loadChildren: () =>
            import('./scenes/pvp/pvp.module').then((m) => m.PvpModule)
    },
    {
        path: 'pve',
        resolve: {
            user: userResolver
        },
        loadChildren: () =>
            import('./scenes/pve/pve.module').then((m) => m.PveModule)
    },
    {
        path: 'event',
        resolve: {
            user: userResolver
        },
        loadChildren: () =>
            import('./scenes/event/event.module').then((m) => m.EventModule)
    },
    {
        path: 'menu',
        resolve: {
            user: userResolver
        },
        loadChildren: () =>
            import('./scenes/menu/menu.module').then((m) => m.MenuModule)
    },
    {
        path: 'tutorial',
        resolve: {
            user: userResolver
        },
        loadChildren: () =>
            import('./scenes/tutorial/tutorial.module').then(
                (m) => m.TutorialModule
            )
    },
    {
        path: '',
        redirectTo: '/tutorial',
        pathMatch: 'full'
    }
];
